import React, { Component } from "react";

import FormInputText from "./FormInputText";
import FormInputGeoLocate from "./FormInputGeoLocate";
import FormInputDisplay from "./FormInputDisplay";
import FormInputTextArea from "./FormInputTextArea";
import FormInputRadio from "./FormInputRadio";
import FormInputFile from "./FormInputFile";
import FormInputImage from "./FormInputImage";
import FormInputSelect from "./FormInputSelect";
import FormInputCheckbox from "./FormInputCheckbox";
import FormInputEditor from "./FormInputEditor";
import FormInputToggle from "./FormInputToggle";
import FormInputPassword from "./FormInputPassword";
import FormInputHidden from "./FormInputHidden";
import FormInputFileDrag from "./FormInputFileDrag";
import FormInputFancyCheckbox from "./FormInputFancyCheckbox";
import FormLinkedTable from "./FormLinkedTable";
import FormInputSimpleEditor from "./FormInputSimpleEditor";

class FormInput extends Component {
  render() {
    let showOneColumn = this.props.showOneColumn;
    if (typeof showOneColumn === "undefined" || showOneColumn === "")
      showOneColumn = "false";
    const {
      id,
      field,
      name,
      label,
      placeholder,
      type,
      helpText,
      value,
      dropdown,
      colSize,
      defaultValue,
      valueOptions,
      checkboxData,
      error,
      tableName,
      onUpload,
      onChange,
      changeValue,
      onDeleteImage,
      onDeleteFile,
      onValueChange,
      handleCheckboxChange,
      uploadFolder,
    } = this.props;

    switch (type) {
      case "display":
        return (
          <FormInputDisplay
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            changeValue={changeValue}
            error={error}
          />
        );
      case "text":
        return (
          <FormInputText
            field={field}
            showOneColumn={showOneColumn}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            changeValue={changeValue}
            error={error}
          />
        );
      case "number":
        return (
          <FormInputText
            field={field}
            showOneColumn={showOneColumn}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            changeValue={changeValue}
            error={error}
          />
        );
      case "geoLocate":
        return (
          <FormInputGeoLocate
            field={field}
            showOneColumn={showOneColumn}
            name={name}
            value={value}
            colSize={colSize}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            changeValue={changeValue}
            error={error}
          />
        );
      case "password":
        return (
          <FormInputPassword
            field={field}
            type="password"
            label={label}
            name={name}
            value={value}
            colSize={colSize}
            placeholder={placeholder}
            helpText={helpText}
            onChange={onChange}
            changeValue={changeValue}
            error={error}
          />
        );
      case "editor":
      case "content":
        return (
          <FormInputEditor
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            error={error}
          />
        );
      case "simpleEditor":
        return (
          <FormInputSimpleEditor
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            error={error}
          />
        );
      case "textarea":
        return (
          <FormInputTextArea
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            changeValue={changeValue}
            error={error}
          />
        );
      case "radio":
        return (
          <FormInputRadio
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            error={error}
          />
        );
      case "toggle":
        return (
          <FormInputToggle
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            error={error}
          />
        );
      case "checkbox":
        return (
          <FormInputCheckbox
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            handleCheckboxChange={handleCheckboxChange}
            error={error}
          />
        );
      case "fancyCheckbox":
        return (
          <FormInputFancyCheckbox
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            checkboxData={checkboxData}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            handleCheckboxChange={handleCheckboxChange}
            error={error}
          />
        );
      case "select":
      case "dropdown":
        return (
          <FormInputSelect
            field={field}
            showOneColumn={showOneColumn}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            label={label}
            placeholder={placeholder}
            type={type}
            dropdown={dropdown}
            helpText={helpText}
            onChange={onChange}
            changeValue={changeValue}
            error={error}
          />
        );
      case "email":
        return (
          <FormInputText
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            changeValue={changeValue}
            error={error}
          />
        );

      case "upload":
      case "image":
        return (
          <FormInputImage
            id={id}
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            label={label}
            tableName={tableName}
            placeholder={placeholder}
            helpText={helpText}
            onChange={onChange}
            onDeleteImage={onDeleteImage}
            error={error}
          />
        );
      case "file":
        return (
          <FormInputFile
            id={id}
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            label={label}
            tableName={tableName}
            placeholder={placeholder}
            helpText={helpText}
            onUpload={onUpload}
            onValueChange={onValueChange}
            onChange={onChange}
            uploadFolder={uploadFolder}
            onDeleteFile={onDeleteFile}
            error={error}
          />
        );
      case "drag":
        return (
          <FormInputFileDrag
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            label={label}
            placeholder={placeholder}
            helpText={helpText}
            onUpload={onUpload}
            onValueChange={onValueChange}
            onChange={onChange}
            uploadFolder={uploadFolder}
            onDeleteFile={onDeleteFile}
            error={error}
          />
        );

      case "hidden":
        return (
          <FormInputHidden
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            label={label}
            placeholder={placeholder}
            helpText={helpText}
            onUpload={onUpload}
            onValueChange={onValueChange}
            onChange={onChange}
            uploadFolder={uploadFolder}
            onDeleteFile={onDeleteFile}
            error={error}
          />
        );
      case "title":
        return (
          <div className={`col-12 p-2 bg-secondary text-white mb-2`}>
            <b>{label}</b>
          </div>
        );
      case "linkedTable":
        return (
          <FormLinkedTable
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            label={label}
            placeholder={placeholder}
            helpText={helpText}
            tableName={tableName}
            onUpload={onUpload}
            onValueChange={onValueChange}
            onChange={onChange}
            uploadFolder={uploadFolder}
            onDeleteFile={onDeleteFile}
            error={error}
          />
        );
      default:
        return (
          <FormInputText
            field={field}
            name={name}
            value={value}
            colSize={colSize}
            defaultValue={defaultValue}
            valueOptions={valueOptions}
            label={label}
            placeholder={placeholder}
            type={type}
            helpText={helpText}
            onChange={onChange}
            error={error}
          />
        );
    }
  }
}

export default FormInput;
