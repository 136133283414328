import React, { Component } from "react";
import MenteePage from "../chcomponents/MenteePage";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { getList } from "../client/actions/apiActions";

class MenteeAssistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menteeId: 0,
      subPageMode: "loading",
      tableColumns: [
        {
          dataField: "timestamp",
          sort: true,
        },
        {
          dataField: "menteeAssistanceId",
          sort: true,
        },
        {
          dataField: "providerName",
          sort: true,
        },
        {
          dataField: "assistanceValue",
          sort: true,
        },
        {
          dataField: "tools",
          sort: false,
        },
      ],
      fieldData: [
        {
          fieldName: "menteeAssistanceId",
          fieldType: "dropdown",
          colSize: 2,
        },
        {
          fieldName: "timestamp",
          fieldType: "date",
          colSize: 2,
          required: false,
        },
        {
          fieldName: "providerName",
          fieldType: "text",
          colSize: 2,
          required: false,
        },
        {
          fieldName: "assistanceValue",
          fieldType: "text",
          colSize: 2,
          required: false,
        },
        {
          fieldName: "description",
          fieldType: "textarea",
          colSize: 1,
        },
      ],
      fieldDataDefault: {
        name: "",
        startDate: "",
        endDate: "",
        notes: "",
      },
      languageCode: "",
    };
  }
  componentDidMount() {
    let menteeId = this.props.match.params.menteeId;
    let languageCode = this.props.auth.user.languageCode;
    let pathName = this.props.match.path.replace("/:menteeId", "");
    this.setState({ menteeId, languageCode, pathName });
    this.props.getList("menteeAssistance");
  }
  componentWillUnmount() {
    this.setState({ subPageMode: "loading" });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (typeof this.props.data.menteeAssistance !== "undefined") {
        console.log("menteeAssistance", this.props.data.menteeAssistance);
        let fieldData = this.state.fieldData;
        fieldData.map((data, index) => {
          if (data.fieldName === "menteeAssistanceId") {
            fieldData[index].dropdownData = this.props.data.menteeAssistance;
          }
          return null;
        });
        this.setState({ subPageMode: "data", fieldData });
      }
    }
  }
  render() {
    const {
      subPageMode,
      pathName,
      menteeId,
      tableColumns,
      fieldData,
      fieldDataDefault,
      languageCode,
    } = this.state;
    if (subPageMode === "loading") {
      return <Loader />;
    } else {
      return (
        <MenteePage
          tableName="menteeUserAssistance"
          languageTable="Assistance"
          settings={{ showExport: false, showClone: false, showSearch: false }}
          menteeId={menteeId}
          pathName={pathName}
          sortFieldName={"startDate"}
          sortFieldDirection={"DESC"}
          tableColumns={tableColumns}
          fieldData={fieldData}
          fieldDataDefault={fieldDataDefault}
          languageCode={languageCode}
        />
      );
    }
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});
export default connect(mapStateToProps, { getList })(MenteeAssistance);
