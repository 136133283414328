import React, { Component } from "react";
import DataOverview from "../chcomponents/DataOverview";
import { languageText } from "../config"; // Import css
import { getList } from "../client/actions/apiActions";
import { connect } from "react-redux";

const tableName = "menteeAssistance";
class Assistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMode: "loading",
      tableColumns: [
        {
          dataField: "active",
          text: "Status",
          type: "bool",
          sort: true,
        },
        {
          dataField: "parent",
          text: "Parent",
          sort: true,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "tools",
          text: "Tools",
          sort: false,
        },
      ],
      fieldData: [
        {
          fieldName: "name",
          displayName: "Name",
          placeholder: "name",
          fieldType: "text",
          colSize: 2,
          required: true,
          requiredMessage: "Name is required!",
        },
        {
          fieldName: "active",
          displayName: "Active",
          placeholder: "",
          fieldType: "toggle",
          colSize: 2,
          required: false,
        },
        {
          fieldName: "sort",
          displayName: "Sort",
          placeholder: "",
          fieldType: "number",
          colSize: 2,
          required: false,
        },
        {
          fieldName: "parent",
          displayName: "Parent",
          placeholder: "",
          fieldType: "dropdown",
          colSize: 2,
          required: false,
        },
      ],
      fieldDataDefault: {
        name: "",
        active: 1,
      },
    };
  }

  componentDidMount() {
    this.props.getList("menteeAssistance");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (this.props.data.dataType === "saveRecord") {
        this.props.getList("menteeAssistance");
      }
      if (typeof this.props.data.tableName !== "undefined") {
        if (this.props.data.tableName === "menteeAssistance") {
          let fieldData = this.state.fieldData;
          fieldData.map((data, index) => {
            if (data.fieldName === "parent") {
              console.log("dataValues", this.props.data);

              fieldData[index].dropdownData = this.props.data.menteeAssistance;
            }
            return null;
          });
          this.setState({ pageMode: "data", fieldData });
        }
      }
    }
  }

  render() {
    const { tableColumns, fieldData, fieldDataDefault } = this.state;
    const { languageCode } = this.props.auth.user;
    return (
      <DataOverview
        tableName={tableName}
        pageTitle={languageText[languageCode].components[tableName].pageTitle}
        listTitle={languageText[languageCode].components[tableName].listTitle}
        listSubTitle={
          languageText[languageCode].components[tableName].listSubTitle
        }
        addTitle={languageText[languageCode].components[tableName].addTitle}
        addSubTitle={
          languageText[languageCode].components[tableName].addSubTitle
        }
        editTitle={languageText[languageCode].components[tableName].editTitle}
        editSubTitle={
          languageText[languageCode].components[tableName].editSubTitle
        }
        languageInfo={languageText[languageCode]}
        tableColumns={tableColumns}
        fieldData={fieldData}
        fieldDataDefault={fieldDataDefault}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});
export default connect(mapStateToProps, { getList })(Assistance);
