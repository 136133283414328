import React, { Component } from "react";
import { languageText } from "../config";
import DataOverview from "../chcomponents/DataOverview";
import Loader from "../components/Loader";

const tableName = "userRoles";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationId: "",
      pageMode: "loading",

      tableColumns: [
        {
          dataField: "editable",
          type: "bool",
          sort: false,
        },
        {
          dataField: "roleName",
          type: "text",
          sort: true,
        },
        {
          dataField: "roleType",
          sort: true,
        },
        {
          dataField: "tools",
          sort: false,
        },
      ],
      fieldData: [
        {
          fieldName: "editable",
          fieldType: "toggle",
          colSize: 3,
          required: true,
        },
        {
          fieldName: "roleName",
          fieldType: "text",
          colSize: 3,
          required: true,
        },
        {
          fieldName: "roleType",
          fieldType: "radio",
          colSize: 3,
          required: true,
          valueOptions: [
            {
              id: 1,
              value: 1,
              name: "Owner",
            },
            {
              id: 2,
              value: 2,
              name: "Customer",
            },
          ],
        },

        {
          fieldName: "organizationsTitle",
          fieldType: "title",
          colSize: 1,
        },
        {
          fieldName: "organizationsTitleText",
          fieldType: "display",
          colSize: 1,
        },
        {
          fieldName: "organizationsCanView",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "organizationsCanAdd",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "organizationsCanEdit",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "organizationsCanDelete",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "organizationsCanExport",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },

        {
          fieldName: "usersTitle",
          fieldType: "title",
          colSize: 1,
        },
        {
          fieldName: "usersTitleText",
          fieldType: "display",
          colSize: 1,
        },
        {
          fieldName: "usersCanView",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "usersCanAdd",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "usersCanEdit",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "usersCanDelete",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "usersCanExport",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },

        {
          fieldName: "menteesTitle",
          fieldType: "title",
          colSize: 1,
        },
        {
          fieldName: "menteesTitleText",
          fieldType: "display",
          colSize: 1,
        },
        {
          fieldName: "menteesCanView",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "menteesCanAdd",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "menteesCanEdit",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "menteesCanArchive",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "menteesCanChat",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "menteesCanDelete",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "menteesCanExport",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },

        {
          fieldName: "tablesTitle",
          fieldType: "title",
          colSize: 1,
        },
        {
          fieldName: "tablesTitleText",
          fieldType: "display",
          colSize: 1,
        },
        {
          fieldName: "tablesCanView",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "tablesCanAdd",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "tablesCanEdit",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "tablesCanArchive",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "tablesCanDelete",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "tablesCanExport",
          fieldType: "toggle",
          colSize: 5,
          required: true,
        },
        {
          fieldName: "tablesCanAccess",
          fieldType: "checkbox",
          colSize: 1,
          valueOptions: [
            {
              name: "Chat Screen",
              value: "chat",
              id: "chat",
            },
            {
              name: "Organizations",
              value: "organizations",
              id: "organizations",
            },
            {
              name: "Team Members",
              value: "users",
              id: "users",
            },

            {
              name: "Mentees",
              value: "manageMentees",
              id: "manageMentees",
            },
            {
              name: "Goals",
              value: "goals",
              id: "goals",
            },
            {
              name: "Assistance",
              value: "assistance",
              id: "assistance",
            },
            {
              name: "Tasks",
              value: "tasks",
              id: "tasks",
            },
            {
              name: "Challenges",
              value: "challenges",
              id: "challenges",
            },
            {
              name: "Jobs",
              value: "jobs",
              id: "jobs",
            },
            {
              name: "e-Learning",
              value: "eLearning",
              id: "eLearning",
            },
            {
              name: "Resources",
              value: "resources",
              id: "resources",
            },

            {
              name: "Mentee-Profile",
              value: "menteeProfile",
              id: "menteeProfile",
            },
            {
              name: "Mentee-Assessments",
              value: "menteeAssessments",
              id: "menteeAssessments",
            },
            {
              name: "Mentee-Assistance",
              value: "menteeAssistance",
              id: "menteeAssistance",
            },
            {
              name: "Mentee-Chat",
              value: "menteeChat",
              id: "menteeChat",
            },
            {
              name: "Mentee-Goals",
              value: "menteeGoals",
              id: "menteeGoals",
            },
            {
              name: "Mentee-Tasks",
              value: "menteeTasks",
              id: "menteeTasks",
            },
            {
              name: "Mentee-Challenges",
              value: "menteeChallenges",
              id: "menteeChallenges",
            },
            {
              name: "Mentee-Notes",
              value: "menteeNotes",
              id: "menteeNotes",
            },
            {
              name: "Mentee-Files",
              value: "menteeFiles",
              id: "menteeFiles",
            },
            {
              name: "Mentee-Export",
              value: "menteeExport",
              id: "menteeExport",
            },
            {
              name: "Mentee-Reset-Password",
              value: "menteeResetPassword",
              id: "menteeResetPassword",
            },
            {
              name: "Mentee-Deactivate",
              value: "menteeDeactivate",
              id: "menteeDeactivate",
            },

            {
              name: "Adverse Experiences",
              value: "adverseExperiences",
              id: "adverseExperiences",
            },
            {
              name: "Content",
              value: "content",
              id: "content",
            },
            {
              name: "Email Templates",
              value: "emailTemplates",
              id: "emailTemplates",
            },
            {
              name: "Gender",
              value: "genders",
              id: "genders",
            },
            {
              name: "Languages",
              value: "languages",
              id: "languages",
            },
            {
              name: "Level of Care",
              value: "levelOfCare",
              id: "levelOfCare",
            },
            {
              name: "Offer Types",
              value: "offerTypes",
              id: "offerTypes",
            },
            {
              name: "Organization Services",
              value: "agencyServices",
              id: "agencyServices",
            },
            {
              name: "Organization Types",
              value: "agencyTypes",
              id: "agencyTypes",
            },
            {
              name: "Program Status",
              value: "programStatus",
              id: "programStatus",
            },
            {
              name: "Sexual Orientation",
              value: "sexualOrientation",
              id: "sexualOrientation",
            },
            {
              name: "Staff Certification",
              value: "staffCertification",
              id: "staffCertification",
            },
            {
              name: "Trauma Inform Status",
              value: "traumaInformStatus",
              id: "traumaInformStatus",
            },
            {
              name: "User Roles",
              value: "userRoles",
              id: "userRoles",
            },
            {
              name: "Bug Tracker",
              value: "bugTracker",
              id: "bugTracker",
            },
          ],
          required: true,
        },
      ],
      fieldDataDefault: {},
    };
  }
  componentDidMount() {
    let organizationId = "";
    if (typeof this.props.match.params.organizationId !== "undefined") {
      organizationId = this.props.match.params.organizationId;
    }
    this.setState({ organizationId: organizationId, pageMode: "data" });
  }

  render() {
    const {
      pageMode,
      tableColumns,
      fieldData,
      fieldDataDefault,
      organizationId,
    } = this.state;
    const { languageCode } = this.props.auth.user.user;
    if (pageMode === "data") {
      return (
        <DataOverview
          tableName={tableName}
          organizationId={organizationId}
          sortFieldName={"roleType"}
          sortDirection={"ASC"}
          pageTitle={languageText[languageCode].components[tableName].pageTitle}
          listTitle={languageText[languageCode].components[tableName].listTitle}
          listSubTitle={
            languageText[languageCode].components[tableName].listSubTitle
          }
          addTitle={languageText[languageCode].components[tableName].addTitle}
          addSubTitle={
            languageText[languageCode].components[tableName].addSubTitle
          }
          editTitle={languageText[languageCode].components[tableName].editTitle}
          editSubTitle={
            languageText[languageCode].components[tableName].editSubTitle
          }
          languageInfo={languageText[languageCode]}
          tableColumns={tableColumns}
          fieldData={fieldData}
          fieldDataDefault={fieldDataDefault}
        />
      );
    } else {
      return <Loader />;
    }
  }
}
export default Users;
